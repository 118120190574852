import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'

import KullaniciYonetimService from '@/common/custom-service/management/KullaniciYonetimService'

export default function useKullanicis() {
  const kullanicisTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { label: 'Kullanıcı', key: 'sKullaniciKodu', sortable: true },
    { label: 'E-Posta', key: 'sEMail', sortable: true },
    { label: 'Telefon', key: 'sTelefon', sortable: true },
    { label: 'Tipi', key: 'kullaniciTipi', sortable: true },
    { label: 'Durum', key: 'isActive', sortable: true },
    { label: 'Aksiyon', key: 'actions' },
  ]

  const perPage = ref(10)
  const toplamKullanici = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = computed({
    get() {
      return store.getters.getKullaniciLocalFilters.sortBy
    },
    set(value) {
      store.commit('setKullaniciFilter', { key: 'sortBy', value })
    },
  })
  const isSortDirDesc = computed({
    get() {
      return store.getters.getKullaniciLocalFilters.sortDesc
    },
    set(value) {
      store.commit('setKullaniciFilter', { key: 'sortDesc', value })
    },
  })

  const dataMeta = computed(() => {
    const localItemsCount = kullanicisTable.value ? kullanicisTable.value.localItems.length : 0
    return {
      from: currentPage.value === 1 ? 1 : perPage.value * (currentPage.value - 1) + 1,
      to: perPage.value * (currentPage.value) < localItemsCount ? perPage.value * (currentPage.value) : localItemsCount,
      of: toplamKullanici.value,
    }
  })

  const refetchData = () => {
    kullanicisTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchKullanicis = () => {
    store.dispatch('fetchKullanicis', { })
  }

  const getKullanicis = computed(() => {
    const kullanicis = store.getters.getKullanicis
    toplamKullanici.value = kullanicis.length
    return kullanicis
  })

  onMounted(fetchKullanicis)

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveKullaniciTipi = role => KullaniciYonetimService.findKullaniciTipiNam(role)

  const resolveUserRoleVariant = role => {
    if (role === 1) return 'primary'
    if (role === 2) return 'warning'
    if (role === 3) return 'success'
    if (role === 4) return 'info'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 1) return 'FrownIcon'
    if (role === 2) return 'UserCheckIcon'
    if (role === 3) return 'UserIcon'
    if (role === 4) return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusName = status => {
    if (status === false) return 'PASİF'
    if (status === true) return 'AKTİF'
    return 'TANIMLI DEĞİL'
  }

  const resolveUserStatusVariant = status => {
    if (status === false) return 'warning'
    if (status === true) return 'success'
    return 'primary'
  }

  return {
    fetchKullanicis,
    getKullanicis,
    tableColumns,
    perPage,
    currentPage,
    toplamKullanici,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    kullanicisTable,

    resolveKullaniciTipi,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusName,
    resolveUserStatusVariant,
  }
}
