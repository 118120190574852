<template>
  <b-card no-body>
    <b-card-body>
      <b-row>

        <b-col cols="12" md="3"
          class="mb-md-0 mb-2">
          <label>{{ $t('Kullanıcı Tipi') }}</label>
          <v-select
            v-model="kullaniciTipiFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="kullaniciTipiOptions"
            class="w-100"
            :reduce="val => val.value" />
        </b-col>

        <b-col cols="12" md="3"
          class="mb-md-0 mb-2">
          <label>{{ $t('Durum') }}</label>
          <v-select
            v-model="isActiveFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="kullaniciStatusOptions"
            class="w-100"
            :reduce="val => val.value" />
        </b-col>

        <b-col cols="12" md="3"
          class="mb-md-0 mb-2">
          <label>{{ $t('Müşteri') }}</label>
          <musteri-selection :musteri-select.sync="musteriIdFilter" />
        </b-col>

        <b-col cols="12" md="3"
          class="mb-md-0 mb-2">
          <label>{{ $t('Arama') }}</label>
          <b-form-input
            v-model="queryFilter"
            class="d-inline-block mr-1"
            :placeholder="$t('Ara...')" />
        </b-col>

      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>

// Options
import StaticOptions from '@/common/options/StaticOptions'
import MusteriSelection from '../selection/MusteriSelection.vue'

export default {
  components: {
    
    MusteriSelection,
  },
  data() {
    return {
      kullaniciTipiOptions: StaticOptions.kullaniciTipiOptions,
      kullaniciStatusOptions: StaticOptions.kullaniciStatusOptions,
    }
  },
  computed: {
    kullaniciTipiFilter: {
      get() {
        return this.$store.getters.getKullaniciLocalFilters.kullaniciTipi
      },
      set(value) {
        this.$store.commit('setKullaniciFilter', { key: 'kullaniciTipi', value })
      },
    },
    isActiveFilter: {
      get() {
        return this.$store.getters.getKullaniciLocalFilters.isActive
      },
      set(value) {
        this.$store.commit('setKullaniciFilter', { key: 'isActive', value })
      },
    },
    musteriIdFilter: {
      get() {
        return this.$store.getters.getKullaniciLocalFilters.musteriId
      },
      set(value) {
        this.$store.commit('setKullaniciFilter', { key: 'musteriId', value })
      },
    },
    queryFilter: {
      get() {
        return this.$store.getters.getKullaniciLocalFilters.query
      },
      set(value) {
        this.$store.commit('setKullaniciFilter', { key: 'query', value })
      },
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
