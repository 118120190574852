<template>
  <div>

    <!-- Buttons -->
    <crud-button :onClickAddNew="addKullanici" :onClickList="fetchKullanicis" v-show="showButtons" />

    <!-- Filters -->
    <kullanici-filters v-show="showFilter" />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2" v-show="showPerPage">

        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('tane göster') }}</label>
          </b-col>
        </b-row>

      </div>
  
      <b-table
        ref="kullanicisTable"
        class="position-relative"
        :items="getKullanicis"
        :per-page="perPage"
        :current-page="currentPage"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Hiçbir eşleşen kayıt bulunamadı"
        :sort-desc.sync="isSortDirDesc">

        <!-- Column: User -->
        <template #cell(sKullaniciKodu)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'manage-kullanici-save', params: { id: data.item.id, musteri_id: -1 } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.sAd + ' ' + data.item.sSoyad }}
            </b-link>
            <small class="text-muted">@{{ data.item.sKullaniciKodu }}</small>
          </b-media>
        </template>

        <!-- Column: Email -->
        <template #cell(sEMail)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.sEMail }}</span>
          </div>
        </template>

        <!-- Column: Telefon -->
        <template #cell(sTelefon)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.sTelefon }}</span>
          </div>
        </template>

        <!-- Column: Role -->
        <template #cell(kullaniciTipi)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.kullaniciTipi)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.kullaniciTipi)}`"
            />
            <span class="align-text-top text-capitalize">{{ resolveKullaniciTipi(data.item.kullaniciTipi) }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(isActive)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.isActive)}`"
            class="text-capitalize">
            {{ resolveUserStatusName(data.item.isActive) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <!-- Update -->
          <feather-icon
            :id="`user-row-${data.item.id}-edit-icon`"
            @click="$router.push({ name: 'manage-kullanici-save', params: { id: data.item.id, musteri_id: -1 } })"
            icon="EditIcon"
            class="cursor-pointer text-primary"
            size="16"
          />

          <!-- Remove -->
          <feather-icon
            :id="`user-row-${data.item.id}-remove-icon`"
            icon="Trash2Icon"
            class="cursor-pointer mx-1 text-danger"
            size="16"
          />
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12" sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">{{ dataMeta.of }} öğeden {{ dataMeta.from }} - {{ dataMeta.to }} arası gösteriliyor</span>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12" sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination
              v-model="currentPage"
              :total-rows="toplamKullanici"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>

import KullaniciFilters from './KullaniciFilters.vue'
import useKullanicis from './useKullanicis'

export default {
  components: {
    KullaniciFilters,
    
  },
  
  props: {
    showButtons: {
      type: Boolean,
      default: true,
    },
    showFilter: {
      type: Boolean,
      default: true,
    },
    showPerPage: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    addKullanici() {
      this.$router.push({ name: 'manage-kullanici-save', params: { id: -1 } });
    },
  },

  setup() {
    const {
      fetchKullanicis,
      getKullanicis,
      tableColumns,
      perPage,
      currentPage,
      toplamKullanici,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      kullanicisTable,

      // UI
      resolveKullaniciTipi,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusName,
      resolveUserStatusVariant,
    } = useKullanicis()
  
    return {
      fetchKullanicis,
      getKullanicis,
      tableColumns,
      perPage,
      currentPage,
      toplamKullanici,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      kullanicisTable,

      // UI
      resolveKullaniciTipi,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusName,
      resolveUserStatusVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
